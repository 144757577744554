import React, { memo, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Section from '../../components/Section/Section';
import ViewAll from '../../components/ViewAll/ViewAll';

import './EventsPreview.scss';

import { useSectionTimeSpent } from '../../util';
import Event from '../Event/Event';

function EventsPreview({ data: { id, name, title } }) {
  const containerRef = useRef(null);

  useSectionTimeSpent(name, containerRef);

  // TODO: order events by beginDate and then endDate, when dates are returned like Y/m/d to be sortable as string
  const { allWpEvent } = useStaticQuery(graphql`
    {
      allWpEvent(sort: { fields: date, order: DESC }, limit: 3) {
        nodes {
          ...EventPreviewContent
        }
      }
    }
  `);

  return (
    <Section id={id}>
      <div id="events_container" className="events-container">
        <h2 className="title">{title}</h2>
        <ul>
          {allWpEvent.nodes?.map((event, index) => {
            return <Event key={index} data={event} />;
          })}
        </ul>
        <ViewAll link="/events/" alignRight />
      </div>
    </Section>
  );
}

export default memo(EventsPreview);

export const fragments = graphql`
  fragment EventsPreview on WpPage_Flexiblelayout_FlexibleChildren_EventsPreview {
    id
    jumpToLinkTitle
    name
    title
  }

  fragment EventsPreview_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_EventsPreview {
    id
    jumpToLinkTitle
    name
    title
  }
`;
